<template>
  <div class="history-box">
    <div class="history-header">
      <div class="search-box">
        <el-date-picker
          size="small"
          type="datetimerange"
          v-model="searchData.date"
          @change="changeTimeHandle"
          value-format="YYYY-MM-DD HH:mm:ss"
          start-placeholder="请选择开始时间"
          end-placeholder="请选择结束时间"
          style="width: 80%"
        ></el-date-picker>
        <!-- <span style="margin: 0 8px">-</span>
        <el-date-picker
          size="small"
          placeholder="结束日期"
          type="datetime"
          v-model="searchData.endTime"
          @change="changeTimeHandle"
          :disabled-date="disabledEndDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          style="width: 40%"
        ></el-date-picker> -->
        <el-button
          size="small"
          @click="getListHandle"
          type="primary"
          icon="el-icon-search"
          style="margin-left: 10px"
          v-if="type == 1"
          >查询</el-button
        >
        <el-button
          v-else
          size="small"
          @click="exportHandle"
          type="primary"
          icon="el-icon-upload"
          style="margin-left: 10px"
          >导出</el-button
        >
      </div>
      <el-tabs v-model="activeName" class="demo-tabs" v-if="type != 1">
        <el-tab-pane label="列表" name="list"></el-tab-pane>
        <el-tab-pane label="图表" name="echart"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="table-list" v-if="activeName === 'list'">
      <el-table :data="tabberData.data" style="width: 100%" height="300">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.pageNo - 1) * tabberData.pageSize + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="时间"
          prop="time"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          :label="propertyName + ' ' + unit"
          prop="propertyText"
          min-width="10%"
        >
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <ml-pagination
          :total="tabberData.total"
          :page="tabberData.pageNo"
          v-model="tabberData.pageNo"
          :size="tabberData.pageSize"
          @current-change="currentChange"
          @size-change="sizeChange"
        />
      </div>
    </div>
    <div class="table-list" v-else-if="activeName === 'echart'">
      <ml-echarts id="a232" :option="lineHistoryOptions(echartsData.data, type)" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import lineHistoryOptions from '@utils/viewData/lineHistoryOptions'
import { downloadFile, formetData } from '@/utils'
import { exportRtuAttrHis } from '@/API/previewApi'
export default {
  name: 'history',
  props: {
    deviceId: {
      type: String,
      default: ''
    },
    propertyCode: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { dispatch } = useStore()
    let activeName = ref('list')
    if (props.type == 1) {
      activeName = ref('echart')
    }
    let propertyName = ref('')
    let unit = ref('')
    let date = new Date()
    let endTime = formetData(date)
    let startTime = formetData(new Date(endTime).getTime() - 7 * 24 * 60 * 60 * 1000)
    let searchData = reactive({
      date: [startTime, endTime],
      deviceId: props.deviceId,
      propertyCode: props.propertyCode,
      startTime: startTime,
      endTime: endTime
    })
    let tabberData = reactive({
      data: [],
      pageNo: 1,
      pageSize: 10,
      total: 0
    })
    let echartsData = reactive({
      data: []
    })
    const changeTimeHandle = value => {
      if (value && value.length > 0) {
        searchData.startTime = value[0]
        searchData.endTime = value[1]
      }
      tabberData.pageNo = 1
      getListHandle()
      getListPageHandle()
    }
    watch(
      () => props.propertyCode,
      newvalue => {
        searchData.propertyCode = newvalue
        if (newvalue) {
          tabberData.pageNo = 1
          getListHandle()
          getListPageHandle()
        }
      }
    )
    const disabledStartDate = value => {
      if (searchData.endTime) {
        return (
          value.getTime() > new Date(searchData.endTime).getTime() ||
          value.getTime() < new Date(searchData.endTime).getTime() - 30 * 24 * 60 * 60 * 1000
        )
      }
      return false
    }

    const disabledEndDate = value => {
      if (searchData.startTime) {
        return (
          value.getTime() > new Date(searchData.startTime).getTime() + 30 * 24 * 60 * 60 * 1000 ||
          value.getTime() < new Date(searchData.startTime).getTime()
        )
      }
      return false
    }
    const getListHandle = () => {
      let params = {
        deviceId: searchData.deviceId,
        propertyCode: searchData.propertyCode,
        startTime: searchData.startTime,
        endTime: searchData.endTime
      }
      dispatch('fetchRtuAttrHisList', params).then(response => {
        if (response.code === 200) {
          echartsData.data = response.data.attrList
          propertyName.value = response.data.propertyName
          unit.value = (echartsData.data && echartsData.data[0] && echartsData.data[0].unit) || ''
        }
      })
    }
    //分页事件
    watch([() => tabberData.pageNo, () => tabberData.pageSize], () => {
      getListPageHandle()
    })
    const currentChange = page => {
      tabberData.pageNo = page
    }
    const sizeChange = size => {
      tabberData.pageSize = size
    }
    const getListPageHandle = () => {
      let params = {
        deviceId: searchData.deviceId,
        propertyCode: searchData.propertyCode,
        startTime: searchData.startTime,
        endTime: searchData.endTime,
        pageIndex: tabberData.pageNo,
        pageSize: tabberData.pageSize
      }
      dispatch('fetchRtuAttrHisListPage', params).then(response => {
        if (response.code === 200) {
          tabberData.data = response.data
          tabberData.total = response.total || 0
        }
      })
    }
    const exportHandle = () => {
      let params = {
        deviceId: searchData.deviceId,
        propertyCode: searchData.propertyCode,
        startTime: searchData.startTime,
        endTime: searchData.endTime
      }
      exportRtuAttrHis(params).then(res => {
        downloadFile(res.data, '设备属性历史数据列表.xlsx')
      })
    }
    onMounted(() => {
      if (props.propertyCode) {
        getListHandle()
        getListPageHandle()
      }
    })
    return {
      activeName,
      searchData,
      changeTimeHandle,
      getListHandle,
      tabberData,
      propertyName,
      disabledStartDate,
      disabledEndDate,
      lineHistoryOptions,
      exportHandle,
      unit,
      getListPageHandle,
      echartsData,
      currentChange,
      sizeChange
    }
  }
}
</script>
<style lang="scss" scoped>
.history-box {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}
.history-header {
  width: 100%;
}
.table-list {
  height: 330px;
}
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.page-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
