<template>
  <div class="equipment-monitor">
    <ml-tip :showHeader="false">
      <div class="equip-tip-box">
        <div class="tip-box">
          <div class="tip-item">
            <span class="tip-label">设备:</span>
            <span>{{ formItem.deviceName }}</span>
          </div>
          <div class="tip-item">
            <span class="tip-label">编号:</span>
            <span>{{ formItem.iotDeviceCode }}</span>
          </div>
          <div class="tip-item">
            <span :class="[formItem.onlineStatus === 'Y' ? 'moitor-green' : 'moitor-red']"></span>
            <span>{{ formItem.onlineStatus === 'Y' ? '在线' : '离线' }}</span>
          </div>
        </div>
        <div class="tip-btn-box">
          <el-button type="primary" size="small" @click="toggleHandle">切换</el-button>
          <el-button @click="goBack" size="small">返回</el-button>
        </div>
      </div>
    </ml-tip>
    <div class="monitor-box" v-if="state">
      <div
        v-for="item in formItem.attrList"
        :key="item.id"
        class="monitor-item"
        @click="openHandle(item)"
      >
        <div class="monitor-header">
          <span class="item-title">{{ item.propertyName }}</span>
          <img :src="require('@/assets/image/icon-monitor.png')" class="icon-monitor" />
        </div>
        <div class="monitor-content">{{ item.propertyText }}{{ item.unit }}</div>
        <div class="monitor-bottom">更新时间：{{ item.time }}</div>
      </div>
      <div v-if="formItem.attrList.length === 0" class="emptry-box">暂无数据</div>
    </div>
    <div class="table-box" v-else>
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="属性"
          prop="propertyName"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="值"
          prop="propertyText"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="标识符"
          prop="propertyCode"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="单位"
          prop="unit"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="更新时间"
          prop="time"
          min-width="10%"
        />
        <el-table-column show-overflow-tooltip align="center" label="操作" min-width="10%">
          <template #default="scope">
            <el-button size="small" type="text" @click="openHandle(scope.row)">历史数据</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <ml-dialog
      width="900px"
      ref="historyRef"
      :showCloseBtn="false"
      :showSubmitBtn="false"
      :title="history.title"
      :showClose="true"
    >
      <template #body>
        <history :deviceId="deviceId" :propertyCode="propertyCode"></history>
      </template>
    </ml-dialog>
  </div>
</template>
<script>
import { onMounted, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import router from '../../router'
import history from './components/history.vue'

export default {
  name: 'EquipmentMonitor',
  components: {
    history
  },
  setup() {
    const { dispatch } = useStore()
    const route = useRoute()
    // 获取路径参数
    const { deviceId } = route.query

    let state = ref(true)
    let propertyCode = ref('')
    let tabberData = reactive({
      data: []
    })
    const formItem = reactive({
      attrList: [],
      deviceId: deviceId,
      deviceName: '',
      iotDeviceCode: '',
      monitorStatus: '',
      onlineStatus: ''
    })

    const getLastRtuList = () => {
      dispatch('fetchLastRtuAttrList', { deviceId }).then(data => {
        if (data.code === 200) {
          formItem.attrList = data.data.attrList || []
          formItem.deviceId = data.data.deviceId || ''
          formItem.deviceName = data.data.deviceName || ''
          formItem.iotDeviceCode = data.data.iotDeviceCode || ''
          formItem.monitorStatus = data.data.monitorStatus || ''
          formItem.onlineStatus = data.data.onlineStatus || ''
          tabberData.data = formItem.attrList
        }
      })
    }
    const toggleHandle = () => {
      state.value = !state.value
    }
    const goBack = () => {
      router.go(-1)
    }

    /* 开始 历史数据 **********************************/
    const historyRef = ref()
    let history = reactive({
      title: '历史数据'
    })
    const openHandle = row => {
      historyRef.value.showDialog = true
      propertyCode.value = row.propertyCode
      console.log(77, row.propertyCode)
    }
    /* 结束 历史数据 **********************************/
    onMounted(() => {
      if (deviceId) {
        getLastRtuList()
      }
    })
    return {
      deviceId,
      getLastRtuList,
      formItem,
      goBack,
      state,
      toggleHandle,
      tabberData,
      openHandle,
      history,
      historyRef,
      propertyCode
    }
  }
}
</script>
<style lang="scss" scoped>
.equipment-action {
  position: relative;
}
.equip-tip-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tip-box {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.tip-item {
  margin-right: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.tip-label {
  margin-right: 5px;
}
.moitor-green {
  display: inline-block;
  border-radius: 8px;
  background: #67c23a;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.moitor-red {
  display: inline-block;
  border-radius: 8px;
  background: #f56c6c;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.monitor-box {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  padding-right: 0;
}
.icon-monitor {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.monitor-item {
  width: 240px;
  margin-right: 16px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
}
.monitor-header {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  padding: 0 10px;
}
.item-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.monitor-content {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #52c432;
  cursor: pointer;
}
.monitor-bottom {
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
}

.emptry-box {
  width: 100%;
  margin-top: 150px;
  text-align: center;
  color: #ccc;
}
</style>
